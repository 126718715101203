import { combineReducers } from 'redux';
import { createReducers } from '../ducksHelpers';
import { appointmentSettings } from './appointment';
import { upcomingAppointmentsSettings } from './upcomingAppointments';
import { previousAppointmentsSettings } from './previousAppointments';
import { canceledAppointmentsSettings } from './canceledAppointments';
import { assessmentReportSettings } from './assessmentReport';

const settingList = [
  appointmentSettings,
  upcomingAppointmentsSettings,
  previousAppointmentsSettings,
  canceledAppointmentsSettings,
  assessmentReportSettings,
];

const clientsReducer = combineReducers(createReducers(settingList));

export default clientsReducer;
