import axios from 'axios';
import { UNAUTH_USER } from '../actions/types';

function getHttpHeader() {
  return {
    Authorization: localStorage.getItem('token'),
  };
}

const postfixes = {
  base: '',
  request: '_REQUEST',
  success: '_SUCCESS',
  failure: '_FAILURE',
};

function getCloneData(dataType, data) {
  if (dataType === 'array') {
    return [...data];
  }

  return { ...data };
}

function getInitialState(dataType) {
  return dataType === 'array' ? [] : {};
}

function getInitialStatusState() {
  return ({
    isRequesting: false,
    error: null,
  });
}

function getActionName(actionTypeBase, postfix) {
  return `${actionTypeBase}${postfix}`;
}

export function ducksActionCreator(
  actionTypeBase,
  httpMethod,
  httpUrl,
  httpBodyData = {},
  checkAuth = true,
  callback = () => {},
  errorCallback = () => {},
) {
  return function (dispatch) {
    dispatch({ type: getActionName(actionTypeBase, postfixes.request) });
    axios({
      url: httpUrl,
      method: httpMethod,
      headers: getHttpHeader(),
      data: httpBodyData,
    })
      .then(response => {
        dispatch({
          type: getActionName(actionTypeBase, postfixes.success),
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        dispatch({
          type: getActionName(actionTypeBase, postfixes.failure),
          payload: error,
        });

        if (checkAuth && error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        } else {
          errorCallback(error);
        }
      });
  };
}

function duckReducer(dataType, actions, state, action) {
  switch (action.type) {
    case getActionName(actions.fetch, postfixes.request):
      return getInitialState(dataType);
    case getActionName(actions.fetch, postfixes.success):
      return getCloneData(dataType, action.payload.data);
    case getActionName(actions.fetch, postfixes.failure):
      return getInitialState(dataType);
    default:
      return state;
  }
}

function duckStatusReducer(actions, state, action) {
  switch (action.type) {
    case getActionName(actions.fetch, postfixes.request):
    case getActionName(actions.create, postfixes.request):
    case getActionName(actions.update, postfixes.request):
    case getActionName(actions.delete, postfixes.request):
      return ({
        isRequesting: true,
        error: null,
      });
    case getActionName(actions.fetch, postfixes.success):
    case getActionName(actions.create, postfixes.success):
    case getActionName(actions.update, postfixes.success):
    case getActionName(actions.delete, postfixes.success):
      return ({
        isRequesting: false,
        error: null,
      });
    case getActionName(actions.fetch, postfixes.failure):
    case getActionName(actions.create, postfixes.failure):
    case getActionName(actions.update, postfixes.failure):
    case getActionName(actions.delete, postfixes.failure):
      return ({
        isRequesting: false,
        error: action.payload,
      });
    default:
      return state;
  }
}

export function createReducers(settingsList) {
  return settingsList.reduce((acc, settings) => ({
    ...acc,
    [settings.stateName]:
      (state = getInitialState(settings.dataType), action) =>
        duckReducer(settings.dataType, settings.actions, state, action),
    // eslint-disable-next-line prefer-template
    [settings.stateName + '_status']:
      (state = getInitialStatusState(), action) =>
        duckStatusReducer(settings.actions, state, action),
  }), {});
}
