import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const transferListSettings = {
  stateName: 'transferList',
  dataType: 'object',
  actions: {
    fetch: 'ducks/payouts/FETCH_TRANSFER_LIST',
  },
};

// Action Creators
export function fetchTransferList(callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    transferListSettings.actions.fetch,
    'get',
    `${API_URL}/practice/stripe_connect/transfer_list`,
    {},
    true,
    callback,
    errorCallback,
  );
}
