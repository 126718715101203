import { combineReducers } from 'redux';
import { createReducers } from '../ducksHelpers';
import { userSettings } from './user';
import { connectAccountSettings } from './connectAccount';
import { payoutPreferenceSettings } from './payoutPreference';
import { payoutStatementSettings } from './payoutStatement';
import { transferListSettings } from './transferList';

const settingList = [
  userSettings,
  connectAccountSettings,
  payoutPreferenceSettings,
  payoutStatementSettings,
  transferListSettings,
];

const payoutsReducer = combineReducers(createReducers(settingList));

export default payoutsReducer;
