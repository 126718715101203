import { combineReducers } from 'redux';
import { createReducers } from '../ducksHelpers';
import { appointmentSettings } from './appointment';
import { appointmentTypesSettings } from './appointmentTypes';
import { blockedSlotSettings } from './blockedSlot';
import { blockedSlotsSettings } from './blockedSlots';
import { estimateSettings } from './estimate';

const settingList = [
  appointmentSettings,
  appointmentTypesSettings,
  blockedSlotSettings,
  blockedSlotsSettings,
  estimateSettings,
];

const scheduleReducer = combineReducers(createReducers(settingList));

export default scheduleReducer;
