import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const appointmentSettings = {
  stateName: 'appointment',
  dataType: 'object',
  actions: {
    update: 'ducks/clients/UPDATE_APPOINTMENT',
  },
};

// Action Creators
export function updateAppointment(id, values, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    appointmentSettings.actions.update,
    'put',
    `${API_URL}/practice/appointments/${id}`,
    { ...values },
    true,
    callback,
    errorCallback,
  );
}
