import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const todayAppointmentsSettings = {
  stateName: 'todayAppointments',
  dataType: 'object',
  actions: {
    fetch: 'ducks/dashboard/FETCH_TODAY_APPOINTMENTS',
  },
};

// Action Creators
export function fetchTodayAppointments(
  page = 1,
  callback = () => {},
  errorCallback = () => {},
) {
  return ducksActionCreator(
    todayAppointmentsSettings.actions.fetch,
    'get',
    `${API_URL}/practice/appointments/paginated_index?today=true&oldest_first=true&per_page=5&current_page=${page}`,
    {},
    true,
    callback,
    errorCallback,
  );
}
