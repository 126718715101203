import { combineReducers } from 'redux';
import { createReducers } from '../ducksHelpers';
import { calendarSubscriptionSettings } from './calendarSubscription';

const settingList = [
  calendarSubscriptionSettings,
];

const preferencesReducer = combineReducers(createReducers(settingList));

export default preferencesReducer;
