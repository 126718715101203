import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const appointmentTypesSettings = {
  stateName: 'appointmentTypes',
  dataType: 'array',
  actions: {
    fetch: 'ducks/schedule/FETCH_APPOINTMENT_TYPES',
  },
};

// Action Creators
export function fetchAppointmentTypes(callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    appointmentTypesSettings.actions.fetch,
    'get',
    `${API_URL}/appointment_types`,
    {},
    true,
    callback,
    errorCallback,
  );
}
