import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const upcomingAppointmentsSettings = {
  stateName: 'upcomingAppointments',
  dataType: 'object',
  actions: {
    fetch: 'ducks/dashboard/FETCH_UPCOMING_APPOINTMENTS',
  },
};

// Action Creators
export function fetchUpcomingAppointments(page = 1, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    upcomingAppointmentsSettings.actions.fetch,
    'get',
    `${API_URL}/practice/appointments/paginated_index?upcoming=true&oldest_first=true&per_page=5&current_page=${page}`,
    {},
    true,
    callback,
    errorCallback,
  );
}
