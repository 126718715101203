import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const userAppointmentSettings = {
  stateName: 'userAppointment',
  dataType: 'object',
  actions: {
    fetch: 'ducks/sessions/FETCH_USER_APPOINTMENT',
    update: 'ducks/sessions/UPDATE_USER_APPOINTMENT',
  },
};

// Action Creators
export function fetchUserAppointment(
  id,
  params = '',
  callback = () => {},
  errorCallback = () => {},
) {
  return ducksActionCreator(
    userAppointmentSettings.actions.fetch,
    'get',
    `${API_URL}/practice/user_appointments/${id}${params}`,
    {},
    true,
    callback,
    errorCallback,
  );
}

export function updateUserAppointment(id, values, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    userAppointmentSettings.actions.update,
    'put',
    `${API_URL}/practice/user_appointments/${id}`,
    { ...values },
    true,
    callback,
    errorCallback,
  );
}
