import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const assessmentReportSettings = {
  stateName: 'assessmentReport',
  dataType: 'object',
  actions: {
    create: 'ducks/payouts/CREATE_ASSESSMENT_REPORT',
  },
};

// Action Creators
export function createAssessmentReport(
  values,
  callback = () => {},
  errorCallback = () => {},
) {
  return ducksActionCreator(
    assessmentReportSettings.actions.create,
    'post',
    `${API_URL}/practice/assessment_reports`,
    { ...values },
    true,
    callback,
    errorCallback,
  );
}
