import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const pendingAppointmentsSettings = {
  stateName: 'pendingAppointments',
  dataType: 'object',
  actions: {
    fetch: 'ducks/dashboard/FETCH_PENDING_APPOINTMENTS',
  },
};

// Action Creators
export function fetchPendingAppointments(
  page = 1,
  callback = () => {},
  errorCallback = () => {},
) {
  return ducksActionCreator(
    pendingAppointmentsSettings.actions.fetch,
    'get',
    `${API_URL}/practice/appointments/paginated_index?pending=true&oldest_first=true&per_page=5&current_page=${page}`,
    {},
    true,
    callback,
    errorCallback,
  );
}
