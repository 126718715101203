import { combineReducers } from 'redux';
import { createReducers } from '../ducksHelpers';
import { userAppointmentSettings } from './userAppointment';
import { appointmentSettings } from './appointment';

const settingList = [
  userAppointmentSettings,
  appointmentSettings,
];

const sessionsReducer = combineReducers(createReducers(settingList));

export default sessionsReducer;
