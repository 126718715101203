import { combineReducers } from 'redux';
import adminDucksReducer from './admin';
import dashboardReducer from './dashboard';
import scheduleReducer from './schedule';
import clientsReducer from './clients';
import billingReducer from './billing';
import sessionsReducer from './sessions';
import payoutsReducer from './payouts';
import preferencesReducer from './preferences';

const ducksReducer = combineReducers({
  admin: adminDucksReducer,
  dashboard: dashboardReducer,
  schedule: scheduleReducer,
  clients: clientsReducer,
  billing: billingReducer,
  sessions: sessionsReducer,
  payouts: payoutsReducer,
  preferences: preferencesReducer,
});

export default ducksReducer;
