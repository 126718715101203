import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const connectAccountSettings = {
  stateName: 'connectAccount',
  dataType: 'object',
  actions: {
    fetch: 'ducks/payouts/FETCH_CONNECT_ACCOUNT',
    create: 'ducks/payouts/CREATE_CONNECT_ACCOUNT',
    update: 'ducks/payouts/UPDATE_CONNECT_ACCOUNT',
  },
};

// Action Creators
export function fetchConnectAccount(callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    connectAccountSettings.actions.fetch,
    'get',
    `${API_URL}/practice/stripe_connect/retrieve_account`,
    {},
    true,
    callback,
    errorCallback,
  );
}

export function createConnectAccount(values, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    connectAccountSettings.actions.create,
    'post',
    `${API_URL}/practice/stripe_connect/create_account`,
    { ...values },
    true,
    callback,
    errorCallback,
  );
}

export function updateConnectAccount(values, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    connectAccountSettings.actions.update,
    'put',
    `${API_URL}/practice/stripe_connect/update_account`,
    { ...values },
    true,
    callback,
    errorCallback,
  );
}
