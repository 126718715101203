import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const canceledAppointmentsSettings = {
  stateName: 'canceledAppointments',
  dataType: 'object',
  actions: {
    fetch: 'ducks/clients/FETCH_CANCELED_APPOINTMENTS',
  },
};

// Action Creators
export function fetchCanceledAppointments(
  params = '',
  callback = () => {},
  errorCallback = () => {},
) {
  return ducksActionCreator(
    canceledAppointmentsSettings.actions.fetch,
    'get',
    `${API_URL}/practice/appointments/paginated_index?canceled=true${params}`,
    {},
    true,
    callback,
    errorCallback,
  );
}
