import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const blockedSlotSettings = {
  stateName: 'blockedSlot',
  dataType: 'object',
  actions: {
    fetch: 'ducks/schedule/FETCH_BLOCKED_SLOT',
    create: 'ducks/schedule/CREATE_BLOCKED_SLOT',
    update: 'ducks/schedule/UPDATE_BLOCKED_SLOT',
    delete: 'ducks/schedule/DELETE_BLOCKED_SLOT',
  },
};

export function fetchBlockedSlot(id, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    blockedSlotSettings.actions.fetch,
    'get',
    `${API_URL}/practice/blocked_slots/${id}`,
    {},
    true,
    callback,
    errorCallback,
  );
}

export function createBlockedSlot(values, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    blockedSlotSettings.actions.create,
    'post',
    `${API_URL}/practice/blocked_slots`,
    { ...values },
    true,
    callback,
    errorCallback,
  );
}

export function updateBlockedSlot(id, values, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    blockedSlotSettings.actions.update,
    'put',
    `${API_URL}/practice/blocked_slots/${id}`,
    { ...values },
    true,
    callback,
    errorCallback,
  );
}

export function deleteBlockedSlot(id, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    blockedSlotSettings.actions.update,
    'delete',
    `${API_URL}/practice/blocked_slots/${id}`,
    {},
    true,
    callback,
    errorCallback,
  );
}
