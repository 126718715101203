import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const calendarSubscriptionSettings = {
  stateName: 'calendarSubscription',
  dataType: 'object',
  actions: {
    fetch: 'ducks/preferences/FETCH_CALENDAR_SUBSCRIPTION',
    update: 'ducks/preferences/UPDATE_CALENDAR_SUBSCRIPTION',
  },
};

// Action Creators
export function fetchCalendarSubscription(callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    calendarSubscriptionSettings.actions.fetch,
    'get',
    `${API_URL}/practice/calendar_subscriptions/me`,
    {},
    true,
    callback,
    errorCallback,
  );
}

export function updateCalendarSubscription(callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    calendarSubscriptionSettings.actions.update,
    'put',
    `${API_URL}/practice/calendar_subscriptions/me`,
    {},
    true,
    callback,
    errorCallback,
  );
}
